<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="800">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Payment Details for Mobile No: {{modalInfo.UserMobile}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ParkingWheelLockID}}</td>
                            <td>{{props.item.TrxnID}}</td>
                            <td>{{props.item.TransactionPaymentDateTime}}</td>
                            <td>{{props.item.TransactionPaymentMethod}}</td>
                            <td>{{props.item.TransactionPayment}}</td>
                            <td>{{props.item.ParkingWheelLockPaymentTrxRef}}</td>
                        </tr>
                    </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'BL Park ID,', value: 'TicketID' },
                { text: 'Trxn ID', value: 'ParkingPaymentTrxRef' },
                { text: 'Transaction Date', value: 'TransactionPaymentDateTime' },
                { text: 'Payment Method', value: 'TransactionPaymentMethod' },
                { text: 'Amount (RM)', value: 'TransactionPayment'},
                { text: 'Payment Ref. No.', value: 'TransactionPaymentRef' },
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];

            this.tableloading = true;
            let response = await this.$store.dispatch('getBayLockManualExitPaymentTrxnDetails', {id: this.modalInfo.ParkingWheelLockID})
            if (response){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].ParkingPaymentTrxRef = dt[i].ParkingPaymentTrxRef == null ? "N/A" : dt[i].ParkingPaymentTrxRef;
                    dt[i].ParkingWheelLockPaymentTrxRef = dt[i].ParkingWheelLockPaymentTrxRef || 'N/A';
                    dt[i].TransactionPaymentDateTime = dt[i].TransactionPaymentDateTime == null ? "N/A" : moment(dt[i].TransactionPaymentDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].TransactionPaymentMethod = dt[i].TransactionPaymentMethod == null ? "N/A" : dt[i].TransactionPaymentMethod;

                    if (dt[i].TransactionPaymentMethod == "Parking Token"){
                        dt[i].TransactionPayment = dt[i].TransactionPaymentParkingTokenAmount == null ? "N/A" : dt[i].TransactionPaymentParkingTokenAmount;
                    }

                    else{
                        dt[i].TransactionPayment = dt[i].TransactionPaymentMYRAmount == null ? "N/A" : Number(dt[i].TransactionPaymentMYRAmount).toFixed(2);
                    }
                }

                this.items = dt;
                this.tableloading = false;
            }
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>